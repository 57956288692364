<template>
  <div class="drawer">
    <el-drawer :size="360" :visible.sync="visible" :with-header="false" direction="ltr" append-to-body :modal="false">
      <DrawerTitle :name="`7日绩效总览`" />
      <weekRunTime />
      <perfromProgress />
      <DrawerTitle :name="`设备异常信息`" />
      <el-table :data="tableData" max-height="540" style="margin-top: 10px background: #15282f;" :row-class-name="tableRowClassName">
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
        <el-table-column width="180" prop="exceptionTime" label="报警时间"> </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import DrawerTitle from '../components/drawerTitle.vue';
import weekRunTime from '../components/weekRunTime.vue';
import perfromProgress from '../components/perfromProgress.vue';
export default {
  data() {
    return {
      visible: false,
      tableData: []
    };
  },
  components: {
    DrawerTitle,
    weekRunTime,
    perfromProgress
  },
  created() {
    this.init();
  },
  methods: {
    show() {
      this.visible = true;
    },
    async init() {
      const res = await this.$apis.LSD.AlarmDevice();
      this.tableData = res;
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'even';
      } else {
        return 'odd';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ {
  .el-drawer {
    position: relative;
    padding: 15px;
    color: #fff;
    background: #060606;
    opacity: 0.8;
    overflow: inherit;
    .el-drawer__header {
      color: #fff;
      font-size: 18px;
    }
    .status {
      margin: 50px 0;
    }
  }
}
/deep/ .even {
  background: #0b171c;
  color: #fff;
  height: 40px !important;
  border: 1px solid transparent;
}
/deep/ th {
  border-bottom: 0px solid #fff !important;
  color: #fff;
}
/deep/ .el-table td.el-table__cell {
  border-bottom: 0px solid #fff !important;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: 0px solid #fff !important;
}
/deep/ .odd {
  background: #15282f;
  color: #fff;
  height: 40px !important;
  border: 1px solid transparent;
}

/deep/ .cell {
  max-height: 23px !important;
  overflow: hidden !important;
}
/deep/ .el-table thead th.el-table__cell,
.headerClassName,
.headerClassName > th {
  background-color: #15282f !important;
}
/deep/ .el-table--group::after,
.el-table--border::after,
.el-table::before {
  background-color: #15282f;
}
/deep/ .el-table__empty-block {
  background-color: #15282f;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table--scrollable-y .el-table__body-wrapper {
  overflow: hidden;
}
</style>
