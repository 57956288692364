<template>
  <div class="perfromProgress" v-if="weekPerfrom">
    <el-progress class="oee name" :stroke-width="4" :percentage="weekPerfrom.oee - 0"></el-progress>
    <el-progress class="orange name" :stroke-width="4" :percentage="weekPerfrom.availability - 0"></el-progress>
    <el-progress class="green name" :stroke-width="4" :percentage="weekPerfrom.quality - 0"></el-progress>
    <el-progress class="blue name" :stroke-width="4" :percentage="weekPerfrom.performance - 0"></el-progress>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weekPerfrom: null
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const weekPerfrom = await this.$apis.LSD.weekPerfrom();
      this.weekPerfrom = weekPerfrom;
    }
  }
};
</script>

<style lang="scss" scoped>
.perfromProgress {
  display: flex;
  height: 200px !important;
  width: 310px;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  padding-top: 12px;
}
/deep/ .el-progress--line {
  position: relative;
  width: 260px;
  margin-right: 0px;
}
/deep/ .el-progress-bar {
  padding-right: 30px;
}
.oee::before {
  position: absolute;
  content: '设备效率';
  top: -18px;
  font-size: 14px;
  color: #fff;
}
.orange::before {
  position: absolute;
  content: '可用指数';
  top: -18px;
  font-size: 14px;
  color: #fff;
}
.green::before {
  position: absolute;
  content: '质量指数';
  top: -18px;
  font-size: 14px;
  color: #fff;
}
.blue::before {
  position: absolute;
  content: '表现指数';
  top: -18px;
  font-size: 14px;
  color: #fff;
}
/deep/ .el-progress-bar {
  width: 120%;
  margin-right: 0px;
}
/deep/ .el-progress-bar__outer {
  background-color: #082646;
  overflow: unset;
}
/deep/ .oee .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #ffeb9d 0%, #fffbf6 100%);
}
.oee /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #feea9d;
  border-radius: 50%;
}
.oee /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #feea9d;
  border-radius: 50%;
}
/deep/ .orange .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #f39800 0%, #fdde68 100%);
}
.orange /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #f39800;
  border-radius: 50%;
}
.orange /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #f39800;
  border-radius: 50%;
}
/deep/ .green .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #5ebc38 0%, #90e66d 100%);
}
.green /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #5ebc38;
  border-radius: 50%;
}
.green /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #5ebc38;
  border-radius: 50%;
}
/deep/ .blue .el-progress-bar__inner {
  position: relative;
  background: linear-gradient(-90deg, #438edb 0%, #78c4ff 100%);
}
.blue /deep/ .el-progress-bar__inner::after {
  position: absolute;
  content: '';
  right: -1px;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #438edb;
  border-radius: 50%;
}
.blue /deep/ .el-progress-bar__inner::before {
  position: absolute;
  content: '';
  right: -3px;
  top: -4px;
  width: 10px;
  height: 10px;
  border: 1px solid #438edb;
  border-radius: 50%;
}
/deep/ .el-progress__text {
  position: absolute;
  top: -12px;
  right: -14px;
  color: #fff;
}
</style>
