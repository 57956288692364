<template>
  <div class="deviceOee">
    <div class="lineCharts" ref="lineCharts"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { Oee, availability, quality, performance } from './iconBase64';
export default {
  data() {
    return {
      mTime: null
    };
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  destroyed() {
    clearInterval(this.mTime);
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.lineCharts');
      let myChart = echarts.init(chartDom);
      myChart.clear();
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = ``;
            params.forEach(item => {
              str = str.concat(`${item.marker + item.seriesName}:${((item.value * 1000) / 1000).toFixed(2)}%<br/>`);
            });
            return str;
          },
          backgroundColor: 'rgba(25, 45, 54, 0.7)',
          borderColor: 'rgba(25, 45, 54, 0.7)',
          textStyle: {
            color: '#F3F3F3'
          }
        },
        grid: {
          top: '15%',
          right: '2%',
          left: '3%',
          bottom: '20%'
        },
        legend: {
          data: [{ name: '设备OEE' }, { name: '质量指数' }, { name: '表现指数' }, { name: '可用指数' }],
          top: '1%',
          right: '2%',
          itemWidth: 16,
          itemHeight: 10,
          textStyle: {
            color: '#F3F3F3'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.ChartsData.xaxisValues,
          axisLabel: {
            show: true,
            interval: 0,
            padding: [4, 0, 0, 0],
            textStyle: {
              color: '#FEFEFE'
            }
          },
          axisTick: {
            show: false,
            onGap: 2
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['']
            }
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          formatter: '{value} %',
          nameTextStyle: {
            color: '#FEFEFE'
          },
          max: 100,
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(235, 235, 235, .3)',
              type: 'dashed'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#FEFEFE'
            }
          }
        },
        series: [
          {
            name: '设备OEE',
            type: 'line',
            smooth: true,
            data: this.ChartsData.oee,
            symbolSize: 14,
            symbol: `image://${Oee}`,
            itemStyle: {
              color: '#FFF0B9',
              borderColor: '#fff',
              borderWidth: 10
            }
          },
          {
            name: '质量指数',
            type: 'line',
            smooth: true,
            data: this.ChartsData.quality,
            symbolSize: 14,
            symbol: `image://${quality}`,
            itemStyle: {
              color: '#5EBB38',
              borderColor: '#fff'
            }
          },
          {
            name: '表现指数',
            type: 'line',
            smooth: true,
            data: this.ChartsData.performance,
            symbolSize: 14,
            symbol: `image://${performance}`,
            itemStyle: {
              color: '#438EDB',
              borderColor: '#fff'
            }
          },
          {
            name: '可用指数',
            type: 'line',
            smooth: true,
            data: this.ChartsData.availability,
            symbolSize: 14,
            symbol: `image://${availability}`,
            itemStyle: {
              color: '#F39800',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
      // 下标
      let index = 0;
      myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: index
      });
      index++;
      if (index >= this.ChartsData.xaxisValues.length) {
        index = 0;
      }
      this.$refs.lineCharts.childNodes[1].style.zIndex = 1;
      this.mTime = setInterval(() => {
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index
        });
        index++;

        if (index >= this.ChartsData.xaxisValues.length) {
          index = 0;
          // this.$nextTick(() => {

          // })
        }
        this.$nextTick(() => {
          this.$refs.lineCharts.childNodes[1].style.zIndex = 1;
        });
      }, 10 * 1000);
    }
  }
};
</script>

<style scoped>
.deviceOee,
.lineCharts {
  margin-top: 10px;
  width: 100%;
  height: 230px;
}
.lineCharts:nth-child(2) {
  justify-content: center;
  align-items: center;
  z-index: 1 !important;
  background-color: rgba(25, 45, 54, 0.7) !important;
}
</style>
