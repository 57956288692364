<template>
  <div class="map2D">
    <Mapheader />
    <div class="main">
      <Mapdevice />
    </div>
    <Mapfooter />
    <div class="infos">
      <i class="iconfont icon-dcicon_jt_right_nor" @click="showStatus"></i>
      <Mapdrawer ref="oee" />
    </div>
  </div>
</template>

<script>
import Mapheader from './layout/header';
import Mapfooter from './layout/footer';
import Mapdevice from './layout/device';
import Mapdrawer from './layout/drawer';

export default {
  data() {
    return {
      currentDeviceIndex: 0,
      infoData: {}
    };
  },
  mounted() {
    this.showStatus();
  },
  methods: {
    showStatus() {
      this.$refs.oee.show();
    }
  },
  components: {
    Mapdrawer,
    Mapheader,
    Mapfooter,
    Mapdevice
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: MyFontName;
  src: url(./assets/YouSheBiaoTiHei.TTF);
}
@font-face {
  font-family: stand;
  src: url(./assets/SourceHanSansCN-Regular.otf);
}
@font-face {
  font-family: blod;
  src: url(./assets/SourceHanSansCN-Bold.otf);
}
.name {
  font-family: stand;
}
body {
  width: 1920px;
  .map2D {
    width: 1920px;
    height: 1080px;
    display: flex;
    background-color: #0a161b;
    flex-direction: column;
    .footer {
      height: 240px;
    }
    .header {
      height: 196px;
      box-sizing: border-box;
      padding: 21px 40px;
      .title {
        color: #ffffff;
        font-size: 30px;
      }
    }
    .main {
      height: 665px;
      width: 100%;
      background: url('./image/longjingfactory.png') no-repeat;
      .device-list {
        position: relative;
        .device-item {
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }
  .infos {
    .icon-dcicon_jt_right_nor {
      display: inline-block;
      color: rgb(136, 136, 136);
      border-radius: 3px;
      background: rgba(18, 18, 18, 0.65);
      position: fixed;
      top: 50%;
      left: -2px;
      cursor: pointer;
      z-index: 1;
      &:hover {
        color: #f39800;
      }
    }
  }
}
</style>
