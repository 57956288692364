<template>
  <div class="device-info">
    <el-drawer class="drawer" :size="360" :with-header="false" direction="rtl" :visible.sync="visible" :modal="false">
      <DrawerTitle :name="'设备信息'" />
      <div class="infoDeatil" v-for="item in infoList" :key="item.code">
        <div class="name">{{ item.name + ':' }}</div>
        <div class="value">{{ item.code !== 'deviceStatus' ? infoData[item.code] : infoData[item.code] === 1 ? '在线' : infoData[item.code] === 2 ? '离线' : '未激活' }}</div>
      </div>
      <DrawerTitle :name="'参数信息'" />
      <el-table class="DeviceTable" :data="tableData" height="240" :show-overflow-tooltip="true" style="margin: 20px 0px; background: #15282f" :row-class-name="tableRowClassName">
        <el-table-column prop="paramName" width="220" label="参数名称"> </el-table-column>
        <el-table-column prop="tagValue" label="参数值"> </el-table-column>
      </el-table>
      <DrawerTitle :name="'设备效率'" />
      <div class="DeviceOee" v-if="OeeRate">
        <div class="oee name">
          设备OEE<span style="font-size: 26px; font-family: MyFontName; margin-left: 10px">{{ ((OeeRate.oeeRate - 0) * 100).toFixed(2) || 0 }}%</span>
        </div>
        <div class="name">质量指数</div>
        <el-progress color="#1aaa77" class="green" :stroke-width="6" :percentage="((OeeRate.qualityRate - 0) * 100).toFixed(2) || 0"></el-progress>
        <div class="name">表现指数</div>
        <el-progress color="#f39800" class="orange" :stroke-width="6" :percentage="((OeeRate.performanceRate - 0) * 100).toFixed(2) || 0"></el-progress>
        <div class="name">可用率</div>
        <el-progress color="#4893de" class="blue" :stroke-width="6" :percentage="((OeeRate.availabilityRate - 0) * 100).toFixed(2) || 0"></el-progress>
      </div>
      <DrawerTitle :name="'报警信息'" />
      <el-table :data="AlarmData" height="220" style="margin: 10px 0px; background: #15282f; overflow: hidden" :row-class-name="tableRowClassName">
        <el-table-column prop="alarmName" label="报警内容"> </el-table-column>
        <el-table-column width="180" prop="beginTime" label="报警时间"> </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import DrawerTitle from '../components/drawerTitle.vue';
// import { timerFormatter } from '@/axios/utils.js';
export default {
  props: ['data'],
  data() {
    return {
      visible: false,
      infoList: [
        { name: '设备名称', code: 'name' },
        { name: '运行状态', code: 'deviceStatus' },
        { name: '所属工厂', code: 'factoryName' },
        { name: '所属车间', code: 'workShopName' },
        { name: '所属产线', code: 'lineName' }
      ],
      infoData: {},
      tableData: [],
      AlarmData: [],
      OeeRate: null
    };
  },
  components: {
    DrawerTitle
  },
  methods: {
    async show(item) {
      this.visible = true;
      // 基础信息
      const res = await this.$apis.device.getbasic({ deviceId: item.deviceId });
      this.infoData = res;
      // 参数信息
      const ParamsData = await this.$apis.longjing.getparams({ deviceId: item.deviceId });
      this.tableData = ParamsData;
      // 报警信息
      const LogRes = await this.$apis.Alarm.TimeList({ deviceId: item.deviceId });
      this.AlarmData = LogRes.items;
      // Oee信息
      const OeeRate = await this.$apis.deviceOee.TodayDeviceOee({ deviceId: item.deviceId });
      this.OeeRate = OeeRate;
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'even';
      } else {
        return 'odd';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.device-info {
  /deep/ .drawer {
    .el-drawer {
      padding: 20px;
      color: #fff;
      background: #060606;
      opacity: 0.7;
      .infoDeatil {
        width: 100%;
        display: flex;
        height: 40px;
        .name {
          font-family: stand;
          width: 100px;
        }
        .value {
          font-family: stand;
          flex: 1;
        }
      }
    }
  }
}
/deep/ .even {
  background: #0b171c;
  color: #fff;
  height: 40px !important;
  border: 1px solid transparent;
}
/deep/ th {
  border-bottom: 0px solid #fff !important;
  color: #fff;
}
/deep/ .el-table td.el-table__cell {
  border-bottom: 0px solid #fff !important;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: 0px solid #fff !important;
}
/deep/ .odd {
  background: #15282f;
  color: #fff;
  height: 40px !important;
  border: 1px solid transparent;
}

/deep/ .cell {
  max-height: 23px !important;
  overflow: hidden !important;
}
/deep/ .el-table thead th.el-table__cell,
.headerClassName,
.headerClassName > th {
  background-color: #15282f !important;
}
/deep/ .el-table--group::after,
.el-table--border::after,
.el-table::before {
  background-color: #15282f;
}
/deep/ .el-table__empty-block {
  background-color: #15282f;
}
/deep/ .el-table {
  background-color: #0c171b;
}
/deep/ .el-progress-bar__outer {
  background-color: #53676b;
}
/deep/ .el-progress__text {
  position: absolute;
  top: -15px;
  right: 0;
  color: #fff;
}
/deep/ .el-progress {
  margin-bottom: 20px;
}
/deep/ .el-progress-bar {
  margin-right: 0px;
  padding-right: 0px;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table--scrollable-y .el-table__body-wrapper {
  overflow: hidden;
}
</style>
